import React from "react"

export default (props) => (
  <footer className="site-footer py-5">
    <p className="mt-1">
      &copy;{ new Date().getFullYear() } 
      <a href="https://github.com/aeeux/gatsby-portfolio" target="_blank" rel="noopener noreferrer"> Portfolio</a>,
      by Andreas Erik Eriksen.
    </p>
  </footer>
)